import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Privacy Policy - The Smile Stylist" 
    description="SmileStylist Privacy Policy" 
    keywords="policy, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="hidden-xs _w_275px" >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div>
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-9 wow animated fadeInRight" id="blog">
            <h2>Our Privacy Policy:</h2>
            <p>The Smile Stylist website uses cookies, tracking pixels and related technologies. Cookies are small data files that are served by our platform and stored on your device. Our site uses cookies dropped by us or third parties for a variety of purposes including to operate and personalize the website. Also, cookies may also be used to track how you use the site to target ads to you on other websites.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage